import { ToggleExpandArrows } from 'elements/Icon/ToggleExpandedArrows';
import Loading from 'elements/Loading';
import Text from 'elements/Text';
import { get, isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/dist/client/router';
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  FC,
  useEffect,
  useState,
} from 'react';
import {
  Bookmark,
  Briefcase,
  Clipboard,
  Database,
  DollarSign,
  FileText,
  HelpCircle,
  Home,
  Icon,
  Lock,
  MessageCircle,
  Paperclip,
  Search,
  Send,
  Smile,
  Tool,
  User,
  UserPlus,
  Users,
  Volume2,
  Zap,
} from 'react-feather';

import { AccountTypeNames } from '../../dataLayer/User';
import { useActiveUser } from '../../hooks/useActiveUser';
import useClassManager from '../../hooks/useClassManager';
import { getLinkComponentForType, NavigationItemProps } from './DynamicLink';

const IMAGE_URLS = {
  BOT: '/bot-avatar.png',
};

/* eslint-disable sonarjs/no-duplicate-string */
export enum NAVIGATION_ITEM_TYPES {
  INTERNAL_LINK = 'internal-link',
  EXTERNAL_LINK = 'external-link',
  NON_LINK_ACTION = 'non-link-action',
}

type NavigationItemPropsBase = NavigationItemProps &
  (
    | AnchorHTMLAttributes<HTMLAnchorElement>
    | ButtonHTMLAttributes<HTMLButtonElement>
  );

export type NavigationItemPropsWithIntrinsicAttributes =
  NavigationItemPropsBase & {
    isHidden?: boolean;
    setIsHidden?: (isHidden: boolean) => void;
    hasChildren?: boolean;
    setSidebarOpen?: (args: boolean) => void;
    active?: boolean;
    isLoading?: boolean;
    label?: string;
    isExpandedOnLoad?: boolean;
    classNames?: {
      menuItem?: string;
    };
  } & { children?: NavigationItemPropsBase[] };

const BASE_CLASSES = [
  'group',
  'flex',
  'items-center',
  'mb-1',
  'mx-auto',
  'text-base',
  'font-medium',
  'rounded-md',
  'text-visto',
  'w-full',
];

type IconsProps = {
  [key: string]: Icon;
};

const Icons: IconsProps = {
  Home: Home,
  Clipboard: Clipboard,
  User: User,
  Paperclip: Paperclip,
  Lock: Lock,
  Briefcase: Briefcase,
  Smile: Smile,
  Search: Search,
  HelpCircle: HelpCircle,
  Bookmark: Bookmark,
  MessageCircle: MessageCircle,
  Database: Database,
  Send: Send,
  Zap: Zap,
  Users: Users,
  Volume2: Volume2,
  UserPlus,
  Tool,
  DollarSign,
  FileText,
};

export const NavigationItem: FC<NavigationItemPropsWithIntrinsicAttributes> =
  observer(
    ({
      type,
      name,
      icon,
      className,
      allowedRoles,
      isHidden = false,
      setIsHidden,
      hasChildren = false,
      setSidebarOpen,
      active = true,
      isLoading = false,
      requiredPermissions = null,
      requiredPermissionGroups = null,
      classNames,
      label,
      image,
      ...props
    }) => {
      const user = useActiveUser();
      const router = useRouter();
      const componentClasses = useClassManager(BASE_CLASSES);
      const Component = getLinkComponentForType(type) as any;
      const [current, setCurrent] = useState(false);

      useEffect(() => {
        setCurrent(router.asPath === props.href);
      }, [router.asPath, props.href]);

      let hasPermission = true;

      if (requiredPermissions) {
        hasPermission = !isEmpty(
          requiredPermissions?.filter((permission) =>
            user?.hasPermission(permission.resource, permission.operation)
          )
        );
      }

      if (requiredPermissionGroups) {
        hasPermission = !isEmpty(
          requiredPermissionGroups?.filter((permissionGroupName) =>
            user?.hasPermissionGroup(permissionGroupName)
          )
        );
      }

      const isNavItemHidden =
        !hasPermission ||
        !allowedRoles.includes(user?.type as AccountTypeNames) ||
        !active;

      if (isNavItemHidden) {
        return null;
      }

      if (isLoading) {
        return <Loading.NavItem size={1} />;
      }

      let Icon = null;

      if (icon) {
        Icon = Icons[icon.toString()];
      }

      let imageUrl = null;

      if (image) {
        imageUrl = get(IMAGE_URLS, image, null);
      }

      const handleOnClick = () => {
        setSidebarOpen &&
          type !== NAVIGATION_ITEM_TYPES.NON_LINK_ACTION &&
          setSidebarOpen(false);

        if (type !== NAVIGATION_ITEM_TYPES.NON_LINK_ACTION || !hasChildren) {
          return null;
        }

        setIsHidden && setIsHidden(!isHidden);
      };

      const showArrow =
        hasChildren && setIsHidden && typeof isHidden === 'boolean';

      if (type === NAVIGATION_ITEM_TYPES.EXTERNAL_LINK) {
        props = { ...props, target: '_blank' };
      }

      return (
        <>
          <Component
            onClick={() => handleOnClick()}
            className={componentClasses.toString({
              withClasses: className,
              withConditionalClasses: {
                'bg-visto text-gray-100 group': () => current,
                'hover:bg-visto-light-ultra group': () => !current,
              },
            })}
            id={`nav-item-${name.replace(/\s+/g, '-').toLowerCase()}`}
            {...props}
          >
            <div className="flex items-center">
              {Icon ? (
                <Icon
                  className={[
                    current ? 'text-white' : 'text-visto ',
                    'mr-3 flex-shrink-0 icon-menu',
                  ]
                    .filter(Boolean)
                    .join(' ')}
                  aria-hidden="true"
                  strokeWidth="2.5"
                />
              ) : null}
              {imageUrl && (
                <img
                  src={imageUrl}
                  alt={label}
                  className="w-[20px] mr-[10px]"
                />
              )}
              <p
                className={`fs15 font-bold ${
                  !current ? 'group-hover:text-visto' : ''
                } ${current && 'text-gray-100'} ${
                  !Icon && !imageUrl && 'ml-8'
                } ${classNames?.menuItem ?? ''}`}
              >
                {name}
              </p>
            </div>
            <div className="flex items-center">
              {label && (
                <Text.Paragraph
                  className={`bg-gradient-to-r from-[#2897f2] to-blue-600 rounded-full fs11 font-semibold text-white px-2.5 ${
                    showArrow ? 'mr-1' : 'mr-3'
                  }`}
                >
                  {label}
                </Text.Paragraph>
              )}
              {showArrow && (
                <ToggleExpandArrows
                  className="mr-2"
                  size={26}
                  isHidden={isHidden}
                  setIsHidden={setIsHidden}
                  as="div"
                />
              )}
            </div>
          </Component>
        </>
      );
    }
  );

export default NavigationItem;
