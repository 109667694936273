import { GovernmentApplicationForm } from 'generated/graphql';

export const APPLICATION_DOCUMENTS_IRCC = [
  GovernmentApplicationForm.Imm_1295,
  GovernmentApplicationForm.Imm_1294,
  GovernmentApplicationForm.Imm_5257,
  GovernmentApplicationForm.Imm_5707,
  GovernmentApplicationForm.Imm_5713E,
  GovernmentApplicationForm.Imm_5646E,
  GovernmentApplicationForm.Imm_5257B1,
  GovernmentApplicationForm.Imm_5476,
  GovernmentApplicationForm.Imm_5645E,
  GovernmentApplicationForm.MinorConsent,
  GovernmentApplicationForm.Imm_5708E,
  GovernmentApplicationForm.Imm_5709E,
  GovernmentApplicationForm.Imm_5710E,
  GovernmentApplicationForm.Imm_1344E,
  GovernmentApplicationForm.Imm_5532E,
];

export const ADDITIONAL_APPLICATION_DOCUMENTS_IRCC = [
  'EMPLOYMENT_HISTORY_APPENDIX',
  'SIBLINGS_APPENDIX',
  'CHILDREN_APPENDIX',
];

export const APPLICATION_DOCUMENTS_GOV_PDF_TYPES_COMPANY = [
  'EMP_5624',
  'EMP_5625',
  'COMPANY_LMBP',
  'LMIA_APPROVED',
];

export const APPLICATION_DOCUMENTS_GOV_PDF_TYPES_TALENT = [
  'IMM_1295',
  'IMM_1294',
  'IMM_5257',
  'IMM_5707',
  'IMM_5476',
];

export const APPLICATION_DOCUMENTS_GOV_PDF_TYPES = [
  ...APPLICATION_DOCUMENTS_GOV_PDF_TYPES_TALENT,
  ...APPLICATION_DOCUMENTS_GOV_PDF_TYPES_COMPANY,
];

export const ROLE_SUPPORTING_APPLICATION_DOCUMENTS = [
  'EMPLOYER_RESPONSIBILITY',
];

export const BYTES_IN_MB = 1000000;
const MAX_FILE_SIZE_MB = 4;
const MAX_VIDEO_SIZE_MB = 512;

export const uploadFileMaxSize = BYTES_IN_MB * MAX_FILE_SIZE_MB;
export const uploadVideoMaxSize = BYTES_IN_MB * MAX_VIDEO_SIZE_MB;
export const fileSizeLimitString = ` ${MAX_FILE_SIZE_MB} MB`;
export const videoSizeLimitString = `${MAX_VIDEO_SIZE_MB} MB`;
export const multipleFileUploadLimit = 15;

export const ALLOWED_DOCUMENTS_TO_ADD_TO_SUPPORTING_DOCUMENT_TASK = [
  'PASSPORT',
  'RESUME',
  'PROOF_EDUCATION',
  'PROOF_WORK_EXPERIENCE',
  'MARRIAGE_CERTIFICATE',
  'BIRTH_CERTIFICATE',
  'PHOTO_HEAD_SHOT',
  'WORK_CONTRACT_AND_CONFIRMATION_LETTER',
  'TALENT_EMPLOYMENT_AGREEMENT',
  'PROOF_LEGAL_STATUS',
  'POLICE_CERTIFICATE',
  'EDUCATION_HOUSING_SUPPORTING',
  'LETTER_EXPLANATION',
  'LANGUAGE_EXAM_SDS',
  'EDUCATION_ACCEPTANCE_LETTER',
  'LANGUAGE_EXAM',
  'PROOF_COMMON_LAW_STATUS',
  'MEDICAL_EXAM',
  'GUARDIAN_GOVERNMENT_ID',
  'EDUCATION_SUPPORTING',
  'EXPIRED_PASSPORTS',
  'TRAVEL_HISTORY',
  'NATIONAL_IDENTITY',
  'MEDICAL_EXAM_OPTIONAL',
  'TRAVEL_ITINERARY',
  'BANK_DETAILS',
  'PROOF_EMPLOYMENT',
  'PROOF_EMPLOYMENT_OPTIONAL',
  'PROOF_RELATIONSHIP',
  'LETTER_INVITATION',
  'LOW_INCOME_CUTOFF',
  'MEDICAL_INSURANCE',
  'PROOF_LEGAL_STATUS_CANADA',
  'CHILDRENS_BIRTH_CERTIFICATES',
  'PROOF_FAMILY_HOME_COUNTRY',
  'PROOF_ASSETS_HOME_COUNTRY',
  'PROOF_TIES_HOME_COUNTRY',
  'PASSPORT_PHOTO_CANADA',
];
