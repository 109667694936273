/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
import { Transition } from '@headlessui/react';
import { GoogleTranslateSelect } from 'components/Translate/GoogleTranslate';
import Account from 'dataLayer/Account';
import { Application } from 'dataLayer/Application';
import User from 'dataLayer/User';
import { NavigationItemContainer } from 'elements/NavigationItem/NavigationItemContainer';
import { Text } from 'elements/Text';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import React, { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { parseJSON } from 'utils/helpers';
import { getSupbaseProjectId } from 'utils/supabase';

import { NavigationItemPropsWithIntrinsicAttributes } from '../../../../elements/NavigationItem/NavigationItem';
import { navigation, supportNavigation } from '../../../../navigation_config';
import { NotificationsIcon } from '../NotificationsIcon';
import { NavProfile } from './NavProfile';

export const SideNav: FC<{
  sidebarOpen: boolean;
  setSidebarOpen?: (args: boolean) => void;
  // eslint-disable-next-line sonarjs/cognitive-complexity
}> = observer(({ sidebarOpen = false, setSidebarOpen }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | null>(null);
  const [navItems, setNavItems] =
    useState<NavigationItemPropsWithIntrinsicAttributes[]>(navigation);

  useAsyncEffect(async ({ isMounted }) => {
    const getApplications = navItems.some(
      (item) =>
        item.name === 'Applications' && item.allowedRoles.includes('APPLICANT')
    );

    let applications: any = [];

    const subscription = await Account.subscription();
    const user = await User.getUserIncludes({
      includes: {
        subscription: true,
      },
    });

    if (getApplications && !user?.isType('COPILOT')) {
      applications = (await Application.getAllIncludes())?.data ?? [];
    }

    await navItems.reduce(
      async (
        prom: Promise<void>,
        item: NavigationItemPropsWithIntrinsicAttributes,
        i: number
      ) => {
        await prom;

        if (item.showIf) {
          const showIf = await item.showIf(user, subscription, applications);

          const show = {
            profile:
              typeof showIf === 'string' && navItems[i].name === 'Profile',
            messenger: showIf && navItems[i].name === 'Messenger',
            chat: showIf && navItems[i].name === 'Copilot AI',
            builder: showIf && navItems[i].name === 'Builder',
            applicationsApplicant:
              showIf && navItems[i].name === 'Applications',
            representative: showIf && navItems[i].name === 'Representatives',
            clients: showIf && navItems[i].name === 'Clients',
            documents: showIf && navItems[i].name === 'Draft Documents',
          };

          if (show.profile) {
            navItems[i].href = `/profile/${showIf}`;
          } else if (show.messenger) {
            navItems[i].active = true;
          } else if (show.builder) {
            navItems[i].active = true;
          } else if (show.chat) {
            navItems[i].active = true;
          } else if (show.applicationsApplicant) {
            navItems[i].active = true;
          } else if (show.representative) {
            navItems[i].active = true;
          } else if (show.clients) {
            navItems[i].active = true;
          } else if (show.documents) {
            navItems[i].active = true;
          } else {
            navItems[i].active = false;
          }

          setNavItems(navItems);
        }

        if (navItems.length === i + 1) {
          setIsLoading(false);
        }
      },
      Promise.resolve()
    );

    if (isMounted) {
      setUser(user as User);
    } else {
      setUser(null);
    }
  }, []);

  const impersonate =
    parseJSON(localStorage.getItem('visto-impersonate') ?? '') ?? null;
  const returnToken = get(impersonate, 'adminToken');
  const returnUrl = get(impersonate, 'returnUrl');

  return (
    <Transition
      appear={false}
      show={sidebarOpen}
      enter="transition ease-in-out duration-1000 transform"
      enterFrom="-translate-x-full"
      enterTo="translate-x-0"
      leave="transition ease-out-in duration-900 transform"
      leaveFrom="translate-x-0"
      leaveTo="-translate-x-full"
      className="fixed left-0 sidebar z-40 top-64px overflow-y-auto bg-white"
      id="sideNav"
      unmount={false}
    >
      <div
        id="sideNavContent"
        className={`flex flex-col flex-shrink-0 bg-white border-r border-visto-gray w-72 h-full ${
          sidebarOpen ? 'is-sidebar-open' : ''
        }`}
      >
        <div className="flex justify-between items-center mx-6 mt-6 md:hidden -m-5">
          <GoogleTranslateSelect
            classNames={{ container: 'md:hidden', menuItems: 'left-0' }}
          />
          <NotificationsIcon
            className="md:hidden w-10 h-10 rounded-full border border-visto-gray flex justify-center items-center hover:bg-gray-100"
            user={user}
          />
        </div>
        <NavProfile setSidebarOpen={setSidebarOpen} />
        <nav className="flex-1 mx-6 ">
          {navItems.map((item, i) => (
            <NavigationItemContainer
              setSidebarOpen={setSidebarOpen}
              key={i}
              item={item}
              isLoading={isLoading}
            />
          ))}
        </nav>
        <div className="p-6">
          {supportNavigation.map((item, i) => (
            <NavigationItemContainer
              setSidebarOpen={setSidebarOpen}
              key={i}
              item={item}
              isLoading={isLoading}
            />
          ))}
          {returnToken && (
            <div className=" bg-green-100 border border-green-300 z-50 p-2 rounded-md flex flex-col justify-center items-center mt-3">
              <Text.Paragraph className="fs12 text-center">
                Impersonating:{' '}
                <span className="font-semibold">{user?.email}</span>
              </Text.Paragraph>
              <button
                className="fs12 font-bold underline mt-2"
                onClick={async () => {
                  toast.loading('Returning to admin...');

                  await fetch('/api/admin', {
                    method: 'POST',
                    headers: new Headers({
                      'Content-Type': 'application/json',
                    }),
                    credentials: 'same-origin',
                    body: JSON.stringify({
                      action: 'SET_COOKIE',
                      token: returnToken,
                    }),
                  })
                    .then(async () => {
                      const supabaseProjectId = getSupbaseProjectId();
                      const key = `sb-${supabaseProjectId}-auth-token`;

                      const impersonateSession = localStorage.getItem(
                        'visto-impersonate-session'
                      );

                      localStorage.setItem(key, impersonateSession ?? '');

                      localStorage.removeItem('visto-impersonate');
                      localStorage.removeItem('visto-impersonate-session');

                      window.location.href = returnUrl;
                    })
                    .catch();
                }}
              >
                Return to Admin
              </button>
            </div>
          )}
          <div className="flex justify-around items-center mt-5 mb-2">
            <div className="pb-0.5 flex justify-around w-full flex-wrap">
              <Link
                href="/legal/privacy-policy"
                className="fs12 text-gray-400 hover:text-gray-800"
              >
                Privacy
              </Link>
              <Link
                href="/legal/terms-of-service"
                className="fs12 text-gray-400 hover:text-gray-800"
              >
                Terms
              </Link>
              <Link
                href="/legal/cookie-policy"
                className="fs12 text-gray-400 hover:text-gray-800"
              >
                Cookies
              </Link>
              <Link
                href="/legal/refund-policy"
                className="fs12 text-gray-400 hover:text-gray-800"
              >
                Refunds
              </Link>
              <Link
                href="/support"
                className="fs12 text-gray-400 hover:text-gray-800"
              >
                Support
              </Link>
            </div>
          </div>

          <Text.Paragraph className="text-center w-full fs12 text-gray-400">
            © 2024 Visto Tech Inc. All rights reserved.
          </Text.Paragraph>
        </div>
      </div>
    </Transition>
  );
});
