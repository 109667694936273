import { FormManager } from '@visto-tech/forms';
import {
  DynamicApiFormValidationSchemaOverride,
  HideComponents,
} from 'components/DynamicApiForm/DynamicApiForm';
import { DynamicFormQuestionGroup } from 'components/DynamicApiForm/DynamicFormQuestionGroup';
import { fromDBForm } from 'hooks/fromDBForm';
import logger from 'js-logger';
import { head, maxBy } from 'lodash';
import { toArray } from 'lodash';
import { makeAutoObservable } from 'mobx';

import backendClient, { makeHeaders } from '../backend';
import {
  ApplicationTemplate,
  CreateFormMutationVariables,
  DeleteFormMutationVariables,
  Form as RawForm,
  FormsQueryVariables,
  FormSubmissionStatus,
  Scalars,
  SubmitFormMutationAnswers,
  SubmitFormMutationArgs,
  UpdateFormMutationVariables,
} from '../generated/graphql';
import { FormSubmission as RawFormSubmission } from '../generated/graphql';
import { FormSubmission } from './FormSubmission';
import { Question } from './Question';
import { QuestionGroup } from './QuestionGroup';

class Form implements RawForm {
  id: RawForm['id'];
  name: RawForm['name'];
  title: RawForm['title'];
  formSubmissions: RawForm['formSubmissions'];
  content: (RawForm['content'][number] & {
    isQuestion: () => boolean;
    isQuestionGroup: () => boolean;
  })[];
  groups: RawForm['groups'];
  accountId?: number | null | undefined;
  description?: string | null | undefined;
  applicationTemplates?: (ApplicationTemplate | null)[] | null | undefined;

  constructor(form: RawForm) {
    this.id = form.id;
    this.name = form.name;
    this.title = form.title;
    this.groups = form.groups;
    this.accountId = form?.accountId;
    this.description = form?.description;
    this.applicationTemplates = form?.applicationTemplates;
    this.formSubmissions = toArray(form.formSubmissions).map(
      (submission) => new FormSubmission(submission)
    );
    this.content = toArray(form.content).map((questionOrGroup) => {
      if (questionOrGroup.__typename === 'Question') {
        return new Question(questionOrGroup);
      }
      if (questionOrGroup.__typename === 'QuestionGrouping') {
        return new QuestionGroup(questionOrGroup);
      }
      throw new TypeError('questionOrGroup was an unknown type...');
    });

    makeAutoObservable(this);
  }

  public static async getFormByName({
    name,
    groupName,
    targetAccountId,
    associations,
    excludeFormId = false,
    formSubmissionStatus,
    questionLabelsToRemove,
    searchTerm,
    onlyMostRecentFormSubmission = false,
    excludeFormSubmissionAnswers = false,
  }: {
    name: string;
    groupName?: string;
    targetAccountId?: number;
    associations?: { [key: string]: string };
    excludeFormId: boolean;
    formSubmissionStatus?: FormSubmissionStatus;
    questionLabelsToRemove?: string[];
    searchTerm?: string;
    onlyMostRecentFormSubmission?: boolean;
    excludeFormSubmissionAnswers?: boolean;
  }) {
    const { data } = await backendClient.getFormByName({
      query: {
        name,
        groupName,
        targetAccountId,
        options: {
          excludeFormId,
          formSubmissionStatus,
          questionLabelsToRemove,
          searchTerm,
          onlyMostRecentFormSubmission,
        },
      },
      associations: associations ? JSON.stringify(associations) : undefined,
      excludeFormSubmissionAnswers,
    });

    if (!data || !data.Form) return null;

    return new Form(data.Form as RawForm);
  }

  public static async getFormByNamePreview({
    name,
    groupName,
    targetAccountId,
    associations,
    excludeFormId = false,
    formSubmissionStatus,
    questionLabelsToRemove,
  }: {
    name: string;
    groupName?: string;
    targetAccountId?: number;
    associations?: { [key: string]: string };
    excludeFormId?: boolean;
    formSubmissionStatus?: FormSubmissionStatus;
    questionLabelsToRemove?: string[];
    searchTerm?: string;
    onlyMostRecentFormSubmission?: boolean;
    excludeFormSubmissionAnswers?: boolean;
  }) {
    const { data } = await backendClient.getFormByNamePreview({
      query: {
        name,
        groupName,
        targetAccountId,
        options: {
          excludeFormId,
          formSubmissionStatus,
          questionLabelsToRemove,
        },
      },
      associations: associations ? JSON.stringify(associations) : undefined,
    });

    if (!data || !data.Form) return null;

    return new Form(data.Form as RawForm);
  }

  public static async getFormForGroups({
    name,
    targetAccountId,
    associations,
    token,
  }: {
    name: string;
    targetAccountId?: number;
    associations?: { [key: string]: any } | JSON;
    token?: string;
  }) {
    let requestHeaders = undefined;

    if (token) {
      requestHeaders = {
        Authorization: `Bearer ${token}`,
      };
    }

    try {
      const { data } = await backendClient.getFormForQuestionGroups(
        {
          query: {
            name,
            targetAccountId,
            associations,
          },
        },
        requestHeaders
      );

      if (!data || !data.Form) {
        return null;
      }

      return new Form(data.Form as RawForm);
    } catch (error) {
      logger.error(error);
    }
  }

  public static async getFormIncludes({
    name,
    targetAccountId,
    associations,
    token,
  }: {
    name: string;
    targetAccountId?: number;
    associations?: { [key: string]: any } | JSON;
    token?: string;
  }) {
    const requestHeaders = makeHeaders({ token });

    try {
      const { data } = await backendClient.getFormForQuestionGroups(
        {
          query: {
            name,
            targetAccountId,
            associations,
          },
        },
        requestHeaders
      );

      if (!data || !data.Form) {
        return null;
      }

      return new Form(data.Form as RawForm);
    } catch (error) {
      logger.error(error);
    }
  }

  public static async getFormForBuilder({
    name,
    targetAccountId,
    associations,
    token,
  }: {
    name: string;
    targetAccountId?: number;
    associations?: { [key: string]: any } | JSON;
    token?: string;
  }) {
    let requestHeaders = undefined;

    if (token) {
      requestHeaders = {
        Authorization: `Bearer ${token}`,
      };
    }

    try {
      const { data } = await backendClient.getFormForBuilder(
        {
          query: {
            name,
            targetAccountId,
            associations,
          },
        },
        requestHeaders
      );

      if (!data || !data.Form) {
        return null;
      }

      return new Form(data.Form as RawForm);
    } catch (error) {
      logger.error(error);
    }
  }

  public static async create(args: CreateFormMutationVariables) {
    try {
      const { data } = await backendClient.createForm(args);

      if (!data || !data.createForm) {
        return null;
      }

      return new Form(data.createForm as RawForm);
    } catch (error) {
      logger.error(error);
    }
  }

  public static async getLeadsForm(name: string) {
    try {
      const { data } = await backendClient.getLeadsForm({
        formName: name,
      });

      if (!data || !data.LeadsForm) {
        return null;
      }

      return new Form(data.LeadsForm as RawForm);
    } catch (error) {
      logger.error(error);
    }
  }

  public static async saveDraft({
    accountId,
    input,
  }: {
    accountId?: number;
    input: {
      formName: Scalars['String'];
      groupName?: string | null | undefined;
      answers: Array<SubmitFormMutationAnswers>;
      talentId?: number | null | undefined;
      associations?: string | null | undefined;
    };
  }) {
    try {
      return await backendClient.updateMostRecentFormDraft({
        accountId,
        input: {
          status: 'DRAFT',
          ...input,
        },
      });
    } catch (error) {
      logger.error(error);
    }
  }

  public static async submit(
    inputs: {
      formName: Scalars['String'];
      groupName?: string | null | undefined;
      answers: Array<SubmitFormMutationAnswers>;
      talentId?: number | null | undefined;
      associations?: string | null | undefined;
    },
    targetAccountId?: number,
    args?: SubmitFormMutationArgs
  ) {
    const { data } = await FormSubmission.create(
      { ...inputs, status: 'PUBLISHED' },
      targetAccountId,
      args
    );

    if (!data?.submitForm) {
      throw new Error('Failed to submit form');
    }

    return data.submitForm;
  }

  public static async submitLeads(
    inputs: {
      formName: Scalars['String'];
      groupName?: string | null | undefined;
      answers: Array<SubmitFormMutationAnswers>;
      talentId?: number | null | undefined;
      associations?: string | null | undefined;
    },
    leadEmbedId: string,
    recaptchaToken: string
  ) {
    const { data } = await FormSubmission.createLeads(
      {
        ...inputs,
        status: 'PUBLISHED',
      },
      leadEmbedId,
      recaptchaToken
    );

    if (!data?.submitLeadsForm) {
      throw new Error('Failed to submit form');
    }

    return data.submitLeadsForm;
  }

  public static async findMany(args: FormsQueryVariables) {
    try {
      return (await backendClient.Forms(args)).data?.Forms;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async delete(args: DeleteFormMutationVariables) {
    try {
      return (await backendClient.deleteForm(args)).data?.deleteForm;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async update(args: UpdateFormMutationVariables) {
    try {
      return (await backendClient.updateForm(args)).data?.updateForm;
    } catch (err) {
      logger.error(err);
    }
  }

  getLatestAnswerForQuestion(questionLabel: string): null | string {
    const latestSubmission: RawFormSubmission | undefined = maxBy(
      this.formSubmissions,
      (fs) => fs.version
    );

    let allAnswers: any[] = [];

    // Merge all Answers from all FormSubmissions into one array
    this.formSubmissions.map((submission) => {
      allAnswers = [...allAnswers, ...(submission.answers ?? [])];
    });

    // Sort all Answers by newest to oldest by their ID
    allAnswers.sort((a, b) => {
      return b.id - a.id;
    });

    // Filter Answers to remove any duplicates, since we sorted first, we will keep the newest
    const filteredAnswers = allAnswers.filter(
      (value, index, self) =>
        index ===
        self.findIndex((t) => t.question.label === value.question.label)
    );

    if (latestSubmission) {
      const answerFromLatestSubmission =
        filteredAnswers.find(
          (answer) => answer.question?.label === questionLabel
        )?.answer || null;

      if (answerFromLatestSubmission) return answerFromLatestSubmission;
    }

    const qs = this.content
      .map((questionOrQuestionGroup) => {
        if (questionOrQuestionGroup.isQuestionGroup()) {
          const questionGroup: QuestionGroup =
            questionOrQuestionGroup as QuestionGroup;
          return questionGroup.questions?.find(
            (q) => q.label === questionLabel
          );
        }

        if (questionOrQuestionGroup.isQuestion()) {
          const question: Question = questionOrQuestionGroup as Question;
          return question.label === questionLabel ? question : undefined;
        }
      })
      .filter((q) => Boolean(q)) as Question[];

    return qs[0]?.getNewestAnswer?.()?.answer || null;
  }

  render({
    formManager,
    variation,
    disabled,
    hide,
    targetAccountId,
    submitFormArgs,
    activeQuestion,
  }: {
    formManager: FormManager<any>;
    variation: any;
    disabled: boolean;
    hide: HideComponents;
    targetAccountId?: number;
    submitFormArgs?: SubmitFormMutationArgs;
    activeQuestion?: string | string[] | null;
  }) {
    return this.content
      .map((questionOrQuestionGroup) => {
        if (questionOrQuestionGroup.isQuestion()) {
          questionOrQuestionGroup = questionOrQuestionGroup as Question;
          return (
            questionOrQuestionGroup as Question
          ).getInputComponentForQuestion({
            formManager,
            variation,
          });
        }

        if (questionOrQuestionGroup.isQuestionGroup()) {
          questionOrQuestionGroup = questionOrQuestionGroup as QuestionGroup;
          return (
            <DynamicFormQuestionGroup
              key={`question-${questionOrQuestionGroup.questions?.length}-questionOrQuestionGroup-${questionOrQuestionGroup.id}`}
              group={questionOrQuestionGroup as QuestionGroup}
              variation={variation}
              formManager={formManager}
              disabled={disabled}
              hide={hide}
              targetAccountId={targetAccountId}
              submitFormArgs={submitFormArgs}
              activeQuestion={activeQuestion}
            />
          );
        }

        return null;
      })
      .flat();
  }

  renderDisplay({
    formManager,
    showCopyButton = true,
  }: {
    formManager: FormManager<any>;
    showCopyButton?: boolean;
  }) {
    return this.content
      .map((questionOrQuestionGroup) => {
        if (questionOrQuestionGroup.isQuestionGroup()) {
          const group = questionOrQuestionGroup as QuestionGroup;

          return group.questions?.map((question) => {
            const meetsConditions =
              question.optimisticallyMeetsAllConditions(formManager);

            if (!meetsConditions) {
              return null;
            }

            return question.getQuestionDisplayComponent({
              formManager,
              question,
              showCopyButton,
            });
          });
        }

        return null;
      })
      .flat();
  }

  // Checks if all of the Questions inside a Form of QuestionGroups passes validation
  public static async validateAnswersForQuestionGroups({
    formName,
    targetAccountId,
    associations,
    validationSchemaOverride,
  }: {
    formName: string;
    targetAccountId?: number;
    associations?: { [key: string]: any };
    validationSchemaOverride?: DynamicApiFormValidationSchemaOverride;
  }) {
    const form = await Form.getFormForGroups({
      name: formName,
      targetAccountId,
      associations,
    });

    return (
      await Promise.all(
        form?.groups?.map(async (group) => {
          const form = await Form.getFormByName({
            name: formName,
            groupName: group?.uniqueKey ?? undefined,
            associations,
            targetAccountId,
            excludeFormId: false,
          });

          if (!form) {
            return true;
          }

          const formManager = fromDBForm(form, validationSchemaOverride);

          const formContent: any = head(form?.content);

          const questions = formContent?.questions ?? [];

          questions
            .filter(
              (q: any) => !q.optimisticallyMeetsAllConditions(formManager)
            )
            .map((q: any) => q.label.toString())
            .forEach((label: string) =>
              formManager.ignoreValidationOnTheseFields.add(label)
            );

          questions
            .filter((q: any) => q.optimisticallyMeetsAllConditions(formManager))
            .map((q: any) => q.label.toString())
            .forEach((label: string) =>
              formManager.ignoreValidationOnTheseFields.delete(label)
            );

          await formManager.validate();

          return !formManager.hasErrors();
        }) ?? []
      )
    ).every((isValid) => isValid);
  }
}

export default Form;
