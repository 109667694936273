/* eslint-disable no-case-declarations */
/* eslint-disable sonarjs/no-identical-functions */
import {
  Checkbox,
  Checkboxes,
  DropdownWithLabel,
  FormManager,
  InputWithLabel,
  RepeaterTable,
} from '@visto-tech/forms';
import { deserialize } from '@visto-tech/yup-database-adapter';
import backendClient from 'backend';
import { RepeaterQuestionsRenderer } from 'components/Questions/RepeaterQuestionsRenderer';
import { DatePickerInputSecondary } from 'elements/Forms/DatePickerInput/DatePickerInputSecondary';
import { HeadingInput } from 'elements/Forms/Heading/HeadingInput';
import { ChildInformationRepeater } from 'elements/Forms/RepeaterInputs/ChildInformationRepeater/ChildInformationRepeater';
import { EducationBackgroundRepeater } from 'elements/Forms/RepeaterInputs/EducationBackgroundRepeater/EducationBackgroundRepeater';
import { EmploymentInformationRepeater } from 'elements/Forms/RepeaterInputs/EmploymentInformationRepeater/EmploymentInformationRepeater';
import { FamilyMembersRepeaterV2 } from 'elements/Forms/RepeaterInputs/FamilyMembersRepeater/FamilyMembersRepeaterV2';
import { MilitaryRepeater } from 'elements/Forms/RepeaterInputs/MilitaryServiceRepeater/MilitaryServiceRepeater';
import { PoliticalGroupRepeater } from 'elements/Forms/RepeaterInputs/PoliticalGroupRepeater/PoliticalGroupRepeater';
import { PreviousCountryRepeater } from 'elements/Forms/RepeaterInputs/PreviousCountryRepeater/PreviousCountryRepeater';
import { PrisonerTreatmentRepeater } from 'elements/Forms/RepeaterInputs/PrisonerTreatmentRepeater/PrisonerTreatmentRepeater';
import { ProjectsRepeater } from 'elements/Forms/RepeaterInputs/ProjectsRepeater/ProjectsRepeater';
import { WorkExperienceRepeater } from 'elements/Forms/RepeaterInputs/WorkExperienceRepeater/WorkExperienceRepeater';
import {
  CopyButton,
  DisplayMultiCheckboxQuestionItem,
  DisplayRepeaterFormQuestion,
  DisplayStaticRepeaterQuestion,
} from 'elements/Question/DisplayQuestionInput';
import { Text } from 'elements/Text';
import logger from 'js-logger';
import { head, isEmpty } from 'lodash';
import _ from 'lodash';
import { makeAutoObservable } from 'mobx';
import { Fragment } from 'react';
import { Bookmark, Trash2 } from 'react-feather';
import toast from 'react-hot-toast';
import { parseJSONOrReturnString } from 'utils/helpers';
import { AnySchema } from 'yup';

import { WYSIWYGInputWithLabel } from '../elements/Forms/WYSIWYG/WYSIWYGInputWithLabel';
import {
  Answer,
  EvaluatedCondition,
  Operator,
  Question as RawQuestion,
  QuestionInputType,
  QuestionOptions,
  QuestionQueryVariables,
  QuestionsQueryVariables,
  SubmitFormMutationArgs,
  UpsertQuestionMutationVariables,
} from '../generated/graphql';
import { DisplayDynamicForm } from './DisplayDynamicForm';
import Form from './Form';

export enum QUESTION_INPUT_VARIATIONS {
  STANDARD = 'standard',
  DYNAMIC_FORM = 'dynamic-form',
  DYNAMIC_FORM_BASIC = 'dynamic-form-basic',
  GETTING_STARTED = 'getting-started',
  HEADER = 'header',
  NO_TITLES = 'no-titles',
}

export const numberDollarInputProps = {
  type: 'number',
  onPaste: (e: any) => {
    e.preventDefault();
    return false;
  },
  onKeyPress: (e: any) => {
    // Allow numbers and periods only
    const rgx = /^[0-9]*\.?[0-9]*$/;
    if (!rgx.test(e.key)) {
      e.preventDefault();
      return false;
    }
  },
};

export class Question implements RawQuestion {
  id: RawQuestion['id'];
  inputType: RawQuestion['inputType'];
  label: RawQuestion['label'];
  tip: RawQuestion['tip'];
  question: RawQuestion['question'];
  questionOptions: QuestionOptions[];
  updatedAt: RawQuestion['updatedAt'];
  createdAt: RawQuestion['createdAt'];
  conditionsRequiredToRenderThisQuestion: RawQuestion['conditionsRequiredToRenderThisQuestion'];
  answers: Answer[];
  validationRules: RawQuestion['validationRules'];
  validationSchema?: AnySchema;
  repeaterForm?: Form;
  accountId?: number | null | undefined;
  maxCharacters?: number | null | undefined;

  constructor(rawQuestion: RawQuestion) {
    if (!rawQuestion) {
      throw new Error(
        'You cannot create a Question instance without passing in Question data.'
      );
    }

    this.id = rawQuestion.id;
    this.question = rawQuestion.question;
    this.label = rawQuestion.label;
    this.tip = rawQuestion.tip;
    this.inputType = rawQuestion.inputType;
    this.questionOptions = rawQuestion.questionOptions;
    this.conditionsRequiredToRenderThisQuestion =
      rawQuestion.conditionsRequiredToRenderThisQuestion;
    this.answers = rawQuestion.answers;
    this.validationRules = rawQuestion.validationRules;
    this.accountId = rawQuestion.accountId;
    this.maxCharacters = rawQuestion.maxCharacters;

    if (rawQuestion.repeaterForm) {
      this.repeaterForm = new Form(rawQuestion.repeaterForm);
    }

    if (this.validationRules) {
      this.validationSchema = deserialize(JSON.parse(this.validationRules));
    }

    makeAutoObservable(this, { validationSchema: false });
  }

  isQuestion() {
    return true;
  }

  isQuestionGroup() {
    return false;
  }

  optimisticallyMeetsAllConditions(
    formManager: FormManager<any>,
    repeaterQuestionInterval?: string
  ) {
    const evaluateFormula = (formula: EvaluatedCondition['formula']) => {
      if (!formula || !formula.question || !formula.expect) {
        return false;
      }

      const questionLabel = repeaterQuestionInterval
        ? `${formula.question.label}-${repeaterQuestionInterval}`
        : formula.question.label;

      const actualValue = formManager.formData[questionLabel]
        ?.toString()
        .trim()
        .toLowerCase();

      let isAllowed = false;

      const expectedValue = parseJSONOrReturnString(formula.expect ?? '');

      switch (formula.operation) {
        case Operator.EqualTo:
          isAllowed = formula.expect === actualValue;
          break;
        case Operator.NotEqualTo:
          isAllowed = formula.expect !== actualValue;
          break;
        case Operator.Contains:
          isAllowed = expectedValue.includes(actualValue);
          break;
        case Operator.NotContains:
          isAllowed = !expectedValue.includes(actualValue);
          break;
        case Operator.GreaterThan:
          isAllowed = actualValue > formula.expect;
          break;
        case Operator.LessThan:
          isAllowed = actualValue < formula.expect;
          break;
      }

      return isAllowed;
    };

    const meetsConditionBasedOnFormula = (condition: EvaluatedCondition) => {
      const questionLabel = repeaterQuestionInterval
        ? `${condition?.formula?.question?.label}-${repeaterQuestionInterval}`
        : condition?.formula?.question?.label;

      if (!questionLabel) {
        return condition.value;
      }

      if (!formManager.formData[questionLabel]) {
        return condition.value;
      }

      return evaluateFormula(condition.formula);
    };

    const transformedConditions = this.transformConditionsForEvaluation();

    if (isEmpty(transformedConditions)) {
      return true;
    }

    return transformedConditions.some((conditions) => {
      return conditions.every(meetsConditionBasedOnFormula);
    });
  }

  getNewestAnswer() {
    return this.answers ? head(this.answers) : null;
  }

  public static async findMany(args: QuestionsQueryVariables) {
    try {
      return (await backendClient.Questions(args)).data?.Questions;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async findFirst(args: QuestionQueryVariables) {
    try {
      return (await backendClient.Question(args)).data?.Question;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async findFirstIncludes({
    label,
    include,
  }: {
    label: string;
    include?: {
      options?: boolean;
    };
  }) {
    try {
      return (
        await backendClient.QuestionIncludes({
          label,
          includeOptions: include?.options ?? false,
        })
      ).data?.Question;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async upsert(args: UpsertQuestionMutationVariables) {
    try {
      return (await backendClient.upsertQuestion(args)).data?.upsertQuestion;
    } catch (err) {
      logger.error(err);
    }
  }

  private transformConditionsForEvaluation() {
    const conditionsGroupedByScope = _.chain(
      this.conditionsRequiredToRenderThisQuestion
    )
      .filter((condition) => !condition.isParentCondition)
      .groupBy('scopeId')
      .map((condition) => condition)
      .value();

    const flattenedChildConditions = _.flatMap(conditionsGroupedByScope);

    const childConditionsGroupedByScope = _.groupBy(
      flattenedChildConditions,
      'scopeId'
    );

    return _.flatMap(
      childConditionsGroupedByScope,
      (childConditions: EvaluatedCondition[]) => {
        const allParentConditions = _.flatMap(
          childConditions,
          'parentConditions'
        );

        if (_.isEmpty(allParentConditions)) {
          return [childConditions];
        }

        const parentConditionsGroupedByScope = _.mapValues(
          _.groupBy(allParentConditions, 'scopeId'),
          (groupedParentConditions) => _.uniqBy(groupedParentConditions, 'id')
        );

        // Replace ids in parentConditions with full objects from allConditions
        const replaceWithFullObjects = (conditions: EvaluatedCondition[]) =>
          conditions.map(
            (pc) =>
              this.conditionsRequiredToRenderThisQuestion.find(
                (cond) => cond.id === pc.id
              ) || pc
          );

        // Create a new object for each parent condition group
        return _.map(parentConditionsGroupedByScope, (parentConditions) => {
          return [
            ..._.cloneDeep(childConditions), // Duplicate the child conditions for each parent group
            ...replaceWithFullObjects(parentConditions), // Replaced with full objects
          ];
        });
      }
    );
  }

  public static transformConditionsForDisplay(
    conditionsRequiredToRenderThisQuestion: EvaluatedCondition[]
  ): {
    childConditions: EvaluatedCondition[];
    parentConditions: EvaluatedCondition[][];
  }[] {
    const conditionsGroupedByScope = _.chain(
      conditionsRequiredToRenderThisQuestion
    )
      .filter((condition) => !condition.isParentCondition)
      .groupBy('scopeId')
      .map((condition) => condition)
      .value();

    const flattenedChildConditions = _.flatMap(conditionsGroupedByScope);

    const childConditionsGroupedByScope = _.groupBy(
      flattenedChildConditions,
      'scopeId'
    );

    return _.map(childConditionsGroupedByScope, (childConditions) => {
      const allParentConditions = _.flatMap(
        childConditions,
        'parentConditions'
      );

      const parentConditionsGroupedByScope = _.mapValues(
        _.groupBy(allParentConditions, 'scopeId'),
        (groupedParentConditions) => _.uniqBy(groupedParentConditions, 'id')
      );

      // Replace ids in parentConditions with full objects from allConditions
      const replaceWithFullObjects = (conditions: any) =>
        conditions.map(
          (pc: any) =>
            conditionsRequiredToRenderThisQuestion.find(
              (cond) => cond.id === pc.id
            ) || pc
        );

      const parentConditionsArray = _.map(
        parentConditionsGroupedByScope,
        replaceWithFullObjects
      );

      return {
        childConditions,
        parentConditions: parentConditionsArray,
      };
    });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  getInputComponentForQuestion({
    formManager,
    targetAccountId,
    submitFormArgs,
  }: {
    formManager: FormManager<any>;
    variation: QUESTION_INPUT_VARIATIONS;
    targetAccountId?: number;
    submitFormArgs?: SubmitFormMutationArgs;
  }) {
    const is = {
      textField: [
        QuestionInputType.Text,
        QuestionInputType.Number,
        QuestionInputType.Email,
        QuestionInputType.Textarea,
      ].includes(this.inputType as QuestionInputType),
    };

    const optional = !this.validationRules && !this.validationSchema && (
      <span className="italic font-light fs12 ml-1">(optional)</span>
    );

    // NOTE: do not use the below for Repeater Questions, it caused a weird bug when storing the data
    const question = (
      <span>
        {this.question}
        {optional}
      </span>
    );

    if (is.textField) {
      const textFieldInputType: {
        [key: string]: string;
      } = {
        TEXT: 'text',
        NUMBER: 'number',
        EMAIL: 'email',
      };

      const inputProps: any = {
        type: textFieldInputType[this.inputType],
        textarea: this.inputType === QuestionInputType.Textarea,
      };

      // Only allow the user to paste in text if there is available space
      if (this?.maxCharacters) {
        inputProps.onPaste = (e: any) => {
          e.preventDefault();

          let pastedText = e?.clipboardData?.getData('text');
          if (this.inputType === QuestionInputType.Number) {
            // Filter out everything except numbers
            pastedText = pastedText?.replace(/\D/g, '');
          }
          const selectionStart = e?.target?.selectionStart; // Note: these dont work on number inputs
          const selectionEnd = e?.target?.selectionEnd;
          const currentText = formManager.formData[this.label];
          const selectionLength = selectionEnd - selectionStart;
          const availableSpace =
            (this?.maxCharacters ?? 0) - (currentText.length - selectionLength);

          if (availableSpace > 0) {
            const textBeforeSelection = currentText.substring(
              0,
              selectionStart
            );
            const textAfterSelection = currentText.substring(
              selectionEnd,
              currentText.length
            );
            const textToPaste = pastedText?.substring(0, availableSpace);

            let newValue =
              textBeforeSelection + textToPaste + textAfterSelection;

            // Default to paste at the end of we cant find the selectionStart
            if (!selectionStart && !selectionEnd) {
              newValue = textBeforeSelection + textAfterSelection + textToPaste;
            }

            formManager.formData[this.label] = newValue;
          }
        };
      }

      if (this.inputType === QuestionInputType.Number) {
        if (!this?.maxCharacters) {
          // Only let them paste in numbers, filter out everything else
          inputProps.onPaste = (e: ClipboardEvent) => {
            e.preventDefault();

            const pastedData = e?.clipboardData?.getData('text');
            const filteredData = pastedData?.replace(/\D/g, '');

            formManager.formData[this.label] += filteredData;
          };
        }

        // Only let them type in numbers and periods
        inputProps.onKeyPress = (e: any) => {
          const rgx = /^[0-9]*\.?[0-9]*$/;
          if (!rgx.test(e.key)) {
            e.preventDefault();
            return false;
          }
        };
      }

      return (
        <InputWithLabel.WithFormManager
          key={`id-question-${this.id}-input`}
          id={`id-question-${this.id}-input-${this.label}`}
          name={this.label}
          formManager={formManager}
          formFieldSetProps={{
            className: 'w-full',
          }}
          inputProps={inputProps}
          tooltip={this.tip ?? undefined}
          placeholder="Enter your answer..."
          maxCharacters={this.maxCharacters ?? undefined}
        >
          {question}
        </InputWithLabel.WithFormManager>
      );
    }

    if (this.inputType === QuestionInputType.Select) {
      return (
        <DropdownWithLabel.WithFormManager
          key={`id-question-${this.id}-select`}
          id={`id-question-${this.id}-select-${this.label}`}
          name={this.label}
          formManager={formManager}
          tooltip={this.tip ?? undefined}
          dropdownProps={{
            children: [
              <option key={`initial-empty-value-${this.id}`} value="" disabled>
                Select one
              </option>,
              ...this.questionOptions.map((q) => (
                <option key={`opt-${q.id}`} value={q.option}>
                  {q.option}
                </option>
              )),
            ],
          }}
        >
          <span className="flex justify-between items-start">
            {question}
            {formManager.formData[this.label] && (
              <button
                type="button"
                className="mr-0.5"
                onClick={() => {
                  if (confirm('Are you sure you want to clear this answer?')) {
                    formManager.formData[this.label] = '';
                  }
                }}
              >
                <Trash2
                  size={16}
                  className="opacity-30 hover:opacity-100 ml-2"
                />
              </button>
            )}
          </span>
        </DropdownWithLabel.WithFormManager>
      );
    }

    if (this.inputType === QuestionInputType.Date) {
      return (
        <DatePickerInputSecondary
          key={`id-question-${this.id}-date-secondary`}
          formManager={formManager}
          label={this.label}
          tooltip={this.tip ?? undefined}
        >
          {question}
        </DatePickerInputSecondary>
      );
    }

    if (this.inputType === QuestionInputType.MultiCheckbox) {
      return (
        <Fragment key={`id-question-${this.id}-select`}>
          <Text.Paragraph className="fs14 font-semibold mb-2">
            {question}
          </Text.Paragraph>
          <Checkboxes.WithFormManager
            name={this.label}
            formManager={formManager}
          >
            {this.questionOptions.map((q) => (
              <Checkbox key={`check-box-${q.id}-${this.id}`} value={q.id}>
                {q.option}
              </Checkbox>
            ))}
          </Checkboxes.WithFormManager>
        </Fragment>
      );
    }

    if (this.inputType === QuestionInputType.Wysiwyg) {
      return (
        <WYSIWYGInputWithLabel
          key={`id-question-${this.id}-select`}
          name={this.label}
          formManager={formManager}
          tooltip={this.tip}
        >
          {question}
        </WYSIWYGInputWithLabel>
      );
    }

    if (this.inputType === QuestionInputType.Heading) {
      return <HeadingInput tooltip={this.tip}>{this.question}</HeadingInput>;
    }

    if (this.inputType === QuestionInputType.FamilyMembers) {
      return (
        <FamilyMembersRepeaterV2
          targetAccountId={targetAccountId}
          submitFormArgs={submitFormArgs}
          label={this.label}
          formManager={formManager}
        >
          {this.question}
        </FamilyMembersRepeaterV2>
      );
    }

    if (this.inputType === QuestionInputType.RepeaterChildInfo) {
      return (
        <ChildInformationRepeater formManager={formManager} label={this.label}>
          {this.question}
        </ChildInformationRepeater>
      );
    }

    if (this.inputType === QuestionInputType.RepeaterEmploymentInfo) {
      return (
        <EmploymentInformationRepeater
          formManager={formManager}
          label={this.label}
        >
          {this.question}
        </EmploymentInformationRepeater>
      );
    }

    if (this.inputType === QuestionInputType.RepeaterPreviousResidence) {
      return (
        <PreviousCountryRepeater formManager={formManager} label={this.label}>
          {this.question}
        </PreviousCountryRepeater>
      );
    }

    if (this.inputType === QuestionInputType.RepeaterEduBackground) {
      return (
        <EducationBackgroundRepeater
          formManager={formManager}
          label={this.label}
        >
          {this.question}
        </EducationBackgroundRepeater>
      );
    }

    if (this.inputType === QuestionInputType.RepeaterWorkExperience) {
      return (
        <WorkExperienceRepeater formManager={formManager} label={this.label}>
          {this.question}
        </WorkExperienceRepeater>
      );
    }

    if (this.inputType === QuestionInputType.RepeaterProjects) {
      return (
        <ProjectsRepeater formManager={formManager} label={this.label}>
          {this.question}
        </ProjectsRepeater>
      );
    }

    if (this.inputType === QuestionInputType.RepeaterMilitaryService) {
      return (
        <MilitaryRepeater formManager={formManager} label={this.label}>
          {this.question}
        </MilitaryRepeater>
      );
    }

    if (this.inputType === QuestionInputType.RepeaterPoliticalGroup) {
      return (
        <PoliticalGroupRepeater formManager={formManager} label={this.label}>
          {this.question}
        </PoliticalGroupRepeater>
      );
    }

    if (this.inputType === QuestionInputType.RepeaterPrisonerTreatment) {
      return (
        <PrisonerTreatmentRepeater formManager={formManager} label={this.label}>
          {this.question}
        </PrisonerTreatmentRepeater>
      );
    }

    if (this.inputType === QuestionInputType.Repeater) {
      if (!this.repeaterForm) {
        toast.error('Repeater questions must have an associated RepeaterForm');
        return null;
      }

      return (
        <RepeaterTable
          form={this.repeaterForm}
          formManager={formManager}
          name={this.label}
          description={this.tip ?? undefined}
          renderer={({ name, formManager }) => {
            return (
              <RepeaterQuestionsRenderer
                formManager={formManager}
                name={name}
                repeaterForm={this.repeaterForm}
              />
            );
          }}
          repetitionSingularTitle={'Entry'}
        >
          {this.question}
        </RepeaterTable>
      );
    }
  }

  getQuestionDisplayComponent({
    formManager,
    question,
    showCopyButton = true,
  }: {
    formManager: FormManager<any>;
    question: any;
    showCopyButton?: boolean;
  }) {
    const is = {
      repeater: this.inputType === QuestionInputType.Repeater,
      multiCheckbox: this.inputType === QuestionInputType.MultiCheckbox,
      heading: this.inputType === QuestionInputType.Heading,
      oldRepeater: [
        QuestionInputType.RepeaterChildInfo,
        QuestionInputType.RepeaterEmploymentInfo,
        QuestionInputType.RepeaterWorkExperience,
        QuestionInputType.RepeaterMilitaryService,
        QuestionInputType.RepeaterPoliticalGroup,
        QuestionInputType.RepeaterPrisonerTreatment,
      ].includes(this.inputType as QuestionInputType),
    };

    if (is.heading) {
      return (
        <div className="col-span-2 flex items-center mt-3">
          <Bookmark className="mr-1.5" />
          {this.question}
        </div>
      );
    }

    const answer = formManager.formData[this.label];

    let isSimpleAnswer = true;
    let Content: any = <p className="notranslate">{answer}</p>;

    if (is.multiCheckbox) {
      isSimpleAnswer = false;

      const multiCheckboxAnswers =
        DisplayDynamicForm.getMultiCheckboxAnswers(question);

      Content = (
        <div className="space-y-2">
          {multiCheckboxAnswers?.map((answerOption: any, i: number) => {
            return (
              <DisplayMultiCheckboxQuestionItem
                key={`checkbox-answer-option-${i}-${question.label}`}
                index={i}
                answer={answerOption}
              />
            );
          })}
        </div>
      );
    }

    if (is.repeater) {
      isSimpleAnswer = false;

      const repeaterForm = DisplayDynamicForm.getRepeaterForm(question);

      Content = repeaterForm?.map((repeaterQuestions, i) => {
        return (
          <DisplayRepeaterFormQuestion
            key={`repeater-question-items-${i}-${repeaterQuestions.label}`}
            index={i}
            repeaterQuestions={repeaterQuestions}
          />
        );
      });
    }

    if (is.oldRepeater) {
      isSimpleAnswer = false;

      const answer: any = DisplayDynamicForm.getStaticRepeaterAnswers(question);

      Content = answer?.map((repeaterQuestions: any, i: number) => {
        return (
          <DisplayStaticRepeaterQuestion
            key={`repeater-static-question-items-${i}-${repeaterQuestions.label}`}
            index={i}
            repeaterQuestions={repeaterQuestions}
          />
        );
      });
    }

    if (isSimpleAnswer && !answer) {
      return null;
    }

    return (
      <div
        className={`bg-gray-100 rounded-md p-3 ${
          is.repeater || is.oldRepeater ? 'col-span-2' : ''
        }`}
      >
        <div className="flex items-start justify-between">
          <Text.Paragraph className="fs14 font-semibold mb-2">
            {this.question}
          </Text.Paragraph>
          {!is.repeater &&
            !is.multiCheckbox &&
            !is.oldRepeater &&
            !is.heading &&
            showCopyButton && (
              <div className="flex items-center">
                <CopyButton value={answer} />
              </div>
            )}
        </div>
        {Content}
      </div>
    );
  }
}

export default Question;
