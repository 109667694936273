import { Transition } from '@headlessui/react';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import NavigationItem, {
  NavigationItemPropsWithIntrinsicAttributes,
} from './NavigationItem';

interface NavigationItemContainerPropTypes {
  item: NavigationItemPropsWithIntrinsicAttributes;
  setSidebarOpen?: (args: boolean) => void;
  isLoading?: boolean;
}

export const NavigationItemContainer: FC<NavigationItemContainerPropTypes> =
  observer(({ item, setSidebarOpen, isLoading = false }) => {
    const [isHidden, setIsHidden] = useState<boolean>(
      item?.isExpandedOnLoad ? false : true
    );
    const hasChildren = !isEmpty(item.children);

    return (
      <div>
        <NavigationItem
          isHidden={isHidden}
          setIsHidden={setIsHidden}
          setSidebarOpen={setSidebarOpen}
          {...item}
          className={`pl-3 py-2 justify-between`}
          hasChildren={hasChildren}
          isLoading={isLoading}
        />
        <Transition
          show={!isHidden}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {item.children &&
            item.children.map((child: any, i: number) => (
              <NavigationItem
                setSidebarOpen={setSidebarOpen}
                key={i}
                {...child}
                className="pl-3 py-2.5"
              />
            ))}
        </Transition>
      </div>
    );
  });

export default NavigationItem;
