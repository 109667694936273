/* eslint-disable sonarjs/prefer-immediate-return */
import { gql, useSubscription } from '@apollo/client';
import User from 'dataLayer/User';
import { useContext, useEffect } from 'react';

import { ActiveUserContext } from '../dataLayer/ActiveUserContext';

// NOTE: if copying this function, make sure to wrap the app in _app.tsx in the context

export const useActiveUser = () => {
  const { user, setUser } = useContext(ActiveUserContext);

  const { data } = useSubscription(
    gql`
      subscription Subscription {
        ActiveUser {
          id
          firstName
          lastName
          email
          createdAt
          updatedAt
          permissions {
            resource
            operation
          }
          account {
            name
            id
            viewed
            settings
            leadsEmbedId
            accountType {
              id
              name
            }
            accountSubType {
              id
              name
            }
          }
          hasCompletedGettingStarted
          hasNewNotifications
          avatarKey
          isPrimaryUser
          permissionGroups
          productsPurchased
          hasLawyerAssociation
          mfaActive
        }
      }
    `
  );

  useEffect(() => {
    if (data?.ActiveUser) {
      setUser(new User(data.ActiveUser));
    }
  }, [data?.ActiveUser, setUser]);

  return user;
};
