export const MUI_LICENSE_KEY =
  '2309230e565634a9677d1038493f9c9eTz04NTc5NSxFPTE3NDEyNzMwOTUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';

export const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS;

export const UPLOAD_ENDPOINT_PATH = process.env.UPLOAD_ENDPOINT_PATH || '';

export const ALLOWED_UPLOAD_RESOLVERS = [
  'uploadAccountDocument',
  'uploadComplianceDocument',
  'updateAccountSettings',
  'uploadApplicationDocument',
  'createStorageFile',
];

export const VISTO_MARKETING_URL =
  process.env.NEXT_PUBLIC_VISTO_MARKETING_URL || 'https://visto.ai/';

export const HOTJAR_ID = 3353176;

export const HOTJAR_SNIPPET_VERSION = 6;

export const NEXT_PUBLIC_CHATBOT_URL =
  process.env.NEXT_PUBLIC_CHATBOT_URL || '';

export const CRISP_CHAT_API_IDENTIFIER =
  process.env.CRISP_CHAT_API_IDENTIFIER || '';

export const CRISP_CHAT_API_KEY = process.env.CRISP_CHAT_API_KEY || '';
